import * as Yup from 'yup';

const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  companyOnlineLinks: ['', '', ''],
  outletPublication: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('Please enter First Name'),
  lastName: Yup.string().required('Please enter Last Name'),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
  outletPublication: Yup.string().required('Please enter Outlet/Publication'),
});
export { INITIAL_VALUES, VALIDATION_SCHEMA };
