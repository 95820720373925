import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import PersonalInformation from 'features/requestCredentials/PersonalInformation';
import CompanyOnlineLinks from 'features/requestCredentials/CompanyOnlineLinks';
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'components/utils/addMailingList';
import styles from './mediaMailingList.module.scss';
import { useMutation } from '@apollo/client';
import {
  SEND_ADD_TO_MEDIA_MAILING_LIST,
  SEND_REQUEST_CREDENTIALS_EMAIL,
} from 'services/graphql/queries/general-emails';
import Spinner from 'components/Spinner';
import { navigate } from 'gatsby';
import TextInput from 'components/form-elements/TextInput';

const AddMailingListModal = ({ toggle }) => {
  const [
    sendEmail,
    { error: emailError, data: emailData, loading: isSendingEmail },
  ] = useMutation(SEND_ADD_TO_MEDIA_MAILING_LIST);

  const sendEmailError = emailError?.message;

  useEffect(() => {
    if (emailError !== undefined || emailData !== undefined) {
      const emailSent = emailData?.mediaMailingEmail?.code
        ?.toString()
        .startsWith('2')
        ? true
        : false;

      navigate('/media', {
        state: { emailSent: emailSent, emailError: sendEmailError },
      });
    }
  }, [emailError, emailData, sendEmailError]);

  return (
    <>
      {isSendingEmail && <Spinner />}
      <Layout>
        <SEO title="Request Credentials" />
        <div className={styles.requestCredentialsModal}>
          <div className={styles.header}>
            <h3>Media Mailing List</h3>
          </div>
          <div className={styles.body}>
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={VALIDATION_SCHEMA}
              onSubmit={async values => {
                const companyLinks = values.companyOnlineLinks.filter(
                  link => link !== ''
                );
                const fields = {
                  title: 'Add to Media Mailing List',
                  name: `${values.firstName} ${values.lastName}`,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  outletPublication: values.outletPublication,
                  companyLinks: companyLinks,
                };
                try {
                  await sendEmail({
                    variables: { fields: fields },
                  });
                } catch (error) {}
              }}
            >
              {({
                values: { companyOnlineLinks },
                isValid,
                dirty,
                submitForm,
                isSubmitting,
              }) => {
                return (
                  <Form>
                    <div className={styles.bodyContent}>
                      <PersonalInformation mailingList />
                      <CompanyOnlineLinks
                        companyOnlineLinks={companyOnlineLinks}
                      />
                      {/* <div className="col-sm-4 mb-4"> */}
                      <TextInput
                        name="outletPublication"
                        placeholder="Outlet/Publication*"
                        showErrorMessages={false}
                      />
                    </div>
                    <div className={styles.footer}>
                      <Button
                        type="app-primary-button"
                        handleClick={async () => await submitForm()}
                        disabled={isSubmitting || !(isValid && dirty)}
                      >
                        Send
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddMailingListModal;
